import statusCube from "../../../assets/illu-status-cube.svg";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import Card from "../Card";
import toast from "react-hot-toast";
import "./TemperatureCard.scss";
import {
  BubbleDataPoint,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  ChartOptions,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  registerables as registerablesJS,
  ScatterDataPoint,
  Title,
  Tooltip,
} from "chart.js";
import party from "party-js";

ChartJS.register(...registerablesJS);
ChartJS.register(Filler, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export interface TemperatureCardProps {
  temperature: number;
}

const initialChartOptions: ChartOptions = {
  scales: {
    yAxes: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

function TemperatureCard(props: TemperatureCardProps) {
  const { temperature } = props;
  const chartRef = useRef<ChartJS>(null);
  const [initialTemperature, setInitialTemperature] = useState<number | undefined>(undefined);
  const [chartData, setChartData] = useState<number[]>([]);
  const [chartOptions, setChartOptions] = useState<ChartOptions>(initialChartOptions);
  const [challengeWon, setChallengeWon] = useState(false);

  useEffect(() => {
    if (!initialTemperature) {
      setChartOptions({
        ...chartOptions,
        scales: { yAxes: { min: Math.floor(temperature - 3), max: Math.floor(temperature + 3) } },
      });
      setInitialTemperature(Number(temperature.toFixed(1)));
    }
    if (chartData.length > 5) {
      chartData.pop();
    }
    if (initialTemperature && temperature > initialTemperature + 2 && !challengeWon) {
      setChallengeWon(true);
      toast.success("Challenge gewonnen!", { duration: 5000 });
    }
    chartData.unshift(Number(temperature.toFixed(1)));
    setChartData(chartData);
    chartRef.current?.update();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [temperature]);

  useEffect(() => {
    const challengeTextElement = document.getElementById("tempChallengeText");
    if (challengeWon) {
      party.confetti(challengeTextElement as any);
    }
  }, [challengeWon]);

  const createGradient = () => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.chartArea.height * 1.5);

    gradient.addColorStop(0, "rgb(0, 87, 255)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    return gradient;
  };

  const chartLabels = ["Jetzt", "2 Sek", "4 Sek", "6 Sek", "8 Sek", "10 Sek"];

  const chartDatasets: ChartData<"line", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> = {
    labels: chartLabels,
    datasets: [{ fill: true, backgroundColor: createGradient, data: chartData }],
  };

  const temperatureChallengeGoalOneDecimalRounded = () => {
    if (initialTemperature) return Math.round((initialTemperature + 2) * 10) / 10;
  };

  const infoContent = () => {
    return (
      <div>
        <h4>Temperatur</h4>
        <p style={{ fontSize: 11 }}>
          Hier findet ein Alerting auf einen dynamisch berechneten Temperatur Schwellenwert statt.
          <br />
          Das Alerting wäre leicht auf andere Sensoren sowie Situationen erweiterbar, wie beispielsweise die Erkennung von Erschütterungen oder weitere Anomalien. Bestimmte Reaktionen können für eine Vielzahl von Ereignissen flexibel festgelegt werden.
        </p>
      </div>
    );
  };

  return (
    <Card title="Temperatur" styles={{ cardStyle: { width: 500 } }} infoContent={infoContent()}>
      <div className="temperature-container">
        <img className="icon" src={statusCube} alt="cube status" />
        <div className="status-container">
          <span className="status-text">{!initialTemperature ? 0 : props.temperature.toFixed(1)} °C</span>
          <span>Würfeltemperatur</span>
        </div>
      </div>
      <Chart ref={chartRef} type="line" data={chartDatasets} options={chartOptions} />
      {initialTemperature ? (
        <div id="tempChallengeText" className="challenge-container">
          Erwärme den inoCube um 2 °C auf {temperatureChallengeGoalOneDecimalRounded()} °C, um die Challenge zu gewinnen!
          <br />
          {challengeWon && <span>Du hast die Challenge gewonnen! 🥳</span>}
        </div>
      ) : null}
    </Card>
  );
}

export default TemperatureCard;
