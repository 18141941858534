import React from "react";
import "./App.scss";
import Login from "./views/Login/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import { AuthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { Route, Routes } from "react-router-dom";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";

const configuration: Configuration = {
  auth: {
    clientId: "f614a785-da94-439e-8bb3-dfe413be7ce9",
    authority: "https://login.microsoftonline.com/5a02a20e-edf5-4419-aa5b-0ad5b634c5d8",
  },
  cache: {
    storeAuthStateInCookie: true,
  },
};

const pca = new PublicClientApplication(configuration);

function App() {
  return (
    <MsalProvider instance={pca}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="dashboard"
          element={
            <AuthenticatedTemplate>
              <Dashboard />
            </AuthenticatedTemplate>
          }
        />
      </Routes>
    </MsalProvider>
  );
}

export default App;
