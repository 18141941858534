import { Suspense, useState } from "react";
import Card from "../Card";
import "./CubeRotationCard.scss";
import { Quaternion } from "three";
import { Canvas } from "@react-three/fiber";
import Cube from "./Cube";
import { InoButton } from "@inovex.de/elements-react";
import { useHotkeys } from "react-hotkeys-hook";

interface CubeRotationCardProps {
  isCubeConnected?: boolean;
  quaternion?: Quaternion;
}

function CubeRotationCard(props: CubeRotationCardProps) {
  const [nullQuaternion, setNullQuaternion] = useState<Quaternion | undefined>(undefined);
  const { isCubeConnected, quaternion } = props;
  const [debug, setDebug] = useState(false);
  useHotkeys("ctrl+alt+d", () => setDebug((prevState) => !prevState));
  const revealed = debug ? "revealed" : "";

  const renderQuarternionData = () => {
    return (
      <pre>
        x: {quaternion?.x}
        <br />
        y: {quaternion?.y}
        <br />
        z: {quaternion?.z}
        <br />
        w: {quaternion?.w}
        <br />
      </pre>
    );
  };

  const infoContent = () => {
    return (
      <div>
        <h4>Lage</h4>
        <p style={{ fontSize: 11 }}>
          Die Lage-Daten aus den Senoren des inoCubes werden nahezu in Echtzeit an das Dashboard weitergereicht.
          <br />
          Als Koordinaten der Lage werden Quaternionen verwendet. Diese werden an das Dashboard übertragen, vom virtuellen Würfel verarbeitet und schließlich dargestellt.
          <br />
          Quaternion:
        </p>
        <p style={{ fontWeight: "600", fontSize: 12 }}>quarternion:</p>
        {renderQuarternionData()}
      </div>
    );
  };

  return (
    <Card title="Lage" infoContent={infoContent()}>
      <div className="content">
        {isCubeConnected === undefined ? (
          <div className="centered-text">Warte auf Verbindung...</div>
        ) : !isCubeConnected ? (
          <div className="centered-text">Keine Verbindung...</div>
        ) : (
          <div className="cube">
            <Canvas>
              <ambientLight intensity={0.25} />
              <pointLight position={[10, 10, 10]} intensity={1} />
              <Suspense fallback={null}>{<Cube quaternion={quaternion} nullQuaternion={nullQuaternion} debugging={debug} />}</Suspense>
            </Canvas>
          </div>
        )}
        <div className={`button-container ${revealed}`}>
          <div>{renderQuarternionData()}</div>
          <InoButton onClick={() => setNullQuaternion(quaternion)}>Kalibrieren</InoButton>
        </div>
      </div>
    </Card>
  );
}

export default CubeRotationCard;
