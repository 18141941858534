import { useEffect, useRef, useState } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { AxesHelper, Mesh, Quaternion } from "three";

interface CubeProps {
  nullQuaternion?: Quaternion
  quaternion?: Quaternion
  debugging?: boolean
}

function Cube(props: CubeProps) {
  const { nullQuaternion, quaternion, debugging } = props

  let q = quaternion
      ? new Quaternion().copy(quaternion)
      : undefined

  const calibration = nullQuaternion
      ? new Quaternion().copy(nullQuaternion).invert()
      : undefined

  if (!!q && !!calibration) {
    q.multiply(calibration)
  }

  const gltf = useLoader(GLTFLoader, "./scene.gltf");
  const primitiveRef = useRef<Mesh>(null!);

  const [axesHelper] = useState(new AxesHelper(5))
  useEffect(() => {
    if (debugging) {
      gltf.scene.add(axesHelper)
    } else {
      gltf.scene.remove(axesHelper)
    }
  }, [debugging, axesHelper, gltf.scene])

  useFrame(() => {
    if (q) {
      primitiveRef.current.setRotationFromQuaternion(new Quaternion(q.x, q.y, q.z, q.w));
    }
  });

  return <primitive ref={primitiveRef} object={gltf.scene} scale={1.75} />;
}

export default Cube;
