import React from "react";
import Icons from "../Icons/Icons";
import "react-tooltip/dist/react-tooltip.css";
import "./IconButton.scss";

export interface IIconButton {
  iconName: keyof typeof Icons;
  iconSize: number;
  text?: string;
  tooltipId?: string;
}

function IconButton(props: IIconButton) {
  const iconOnlyStyle: React.CSSProperties = {
    width: props.iconSize,
    height: props.iconSize,
  };

  return (
    <div data-tooltip-id={props.tooltipId} className={`icon-button${props.text ? " text" : ""}`} style={!props.text ? iconOnlyStyle : undefined}>
      <img className="icon" width={props.iconSize} alt={`${props.iconName} icon`} src={Icons[props.iconName]} />
      {props.text && <div className="text">{props.text}</div>}
    </div>
  );
}

export default IconButton;
