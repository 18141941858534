import { InoButton, InoIcon, InoInput, InoSpinner } from "@inovex.de/elements-react";
import React, { useEffect, useState } from "react";
import "./Login.scss";
import illustration from "../../assets/Illu-Login.svg";
import logo from "../../assets/logo-bg.svg";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { BrokerService } from "../../services/BrokerService";
import { useNavigate } from "react-router-dom";
import { ICube } from "../../models/ICube";

const NAMES = ["Ice Cube", "Curious Cube", "Rolling Stone", "Cuby", "Hans Werner"];
export const CUBE_SCOPE = ["184c953b-fb71-45ee-af63-6e27a1698758/.default"];

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [cube, setCube] = useState<ICube>();
  const { instance, accounts, inProgress } = useMsal();
  const [nameError, setNameError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const getCube = async (accessToken: string, userName: string) => {
      const publishers = await BrokerService.getPublishers(accessToken);
      const publisherId = publishers.find((p) => p.indexOf(userName) > -1);
      if (publisherId) {
        setCube({ publisherId, deviceId: publisherId?.replace(userName + "_", "")!, name: "" });
      }
    };

    const login = async () => {
      setIsLoading(true);
      if (accounts.length === 0 && inProgress === InteractionStatus.None) {
        await instance.loginRedirect({ scopes: CUBE_SCOPE });
      } else if (accounts.length > 0 && inProgress === InteractionStatus.None) {
        await instance.acquireTokenSilent({ account: accounts[0], scopes: CUBE_SCOPE }).then(async (result) => {
          await getCube(result.accessToken, accounts[0].username);
          setIsLoading(false);
        });
      }
    };
    login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, inProgress, accounts]);

  const generateName = () => {
    const random = Math.floor(Math.random() * 5);
    setCube({ ...cube, name: NAMES[random] });
    setNameError("");
  };

  const navigateToDashboard = async () => {
    if (cube?.name) {
      sessionStorage.removeItem("cube");
      sessionStorage.setItem("cube", JSON.stringify(cube));
      navigate("/dashboard");
    } else {
      setNameError("Bitte gib einen Namen ein");
    }
  };

  const handleOnChange = (e: CustomEvent<string>) => {
    setCube({ ...cube, name: e.detail });
  };

  return (
    <div className="login">
      <div className="form">
        {isLoading ? (
          <InoSpinner type="circle" colorScheme="light" height={120} width={120}></InoSpinner>
        ) : (
          <>
            <img className="logo" src={logo} alt="inovex logo" />
            <h1>
              <b>Willkommen {accounts.length > 0 ? accounts[0].name?.split(" ")[0] : ""}!</b>
            </h1>
            <p>{cube ? "Dein inoCube wird initialisiert. Gib ihm solange einen Namen." : "Es ist noch kein Cube von dir verbunden."}</p>
            <InoInput
              className="input"
              label="Name generieren"
              value={cube?.name ? cube.name : ""}
              onValueChange={handleOnChange}
              error={nameError !== ""}
              disabled={!cube}
            >
              {cube && <InoIcon className="icon" slot="icon-trailing" icon="refresh" onClick={generateName} />}
            </InoInput>
            {nameError !== "" && <div className="error">{nameError}</div>}
            <InoButton className="submit" disabled={!cube} onClick={navigateToDashboard}>
              Los geht's
            </InoButton>
          </>
        )}
      </div>
      <img className="illustration" src={illustration} alt="cube illustration" />
    </div>
  );
}

export default Login;
