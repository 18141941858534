import React from "react";
import { Tooltip as ReactTooltip, ITooltip as ReactITooltip } from "react-tooltip";
import "./Tooltip.scss";

export interface ITooltip {
  id?: string;
  settings?: ReactITooltip;
}

function Tooltip(props: React.PropsWithChildren<ITooltip>) {
  return (
    <ReactTooltip className="tooltip" noArrow classNameArrow="tooltip-arrow" clickable id={props.id} {...props.settings}>
      {props.children}
    </ReactTooltip>
  );
}

export default Tooltip;
