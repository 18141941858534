import statusCube from "../../../assets/illu-status-cube.svg";
import statusCloud from "../../../assets/illu-status-cloud.svg";
import statusDashboard from "../../../assets/illu-status-dashboard.svg";
import iconCheckmark from "../../../assets/icon-checkmark.svg";
import iconCheckmarkConnected from "../../../assets/icon-checkmark-connected.svg";
import iconCrossmark from "../../../assets/icon-crossmark.svg";
import Card from "../Card";
import "./StatusConnectivity.scss";

export interface IStatusConnectivty {
  isCubeConnected?: boolean;
  messageCount: number;
}

function StatusConnectivity(props: IStatusConnectivty) {
  const infoContent = () => {
    return (
      <div>
        <h4>Status & Connectivity</h4>
        <p style={{ fontSize: 11 }}>
          Die vom inoCube übermittelten Daten fließen zunächst an ein Edge Device bzw. hier stellvertretend an die Smartphone-App. Anschließend werden sie von dort in die Cloud geschickt, verarbeitet und anschließend wiederum weiter an das Dashboard geleitet - und das alles nahezu in Echtzeit.
        </p>
        <p style={{ fontWeight: "600", fontSize: 12 }}>
          Anzahl der übertragenen Messages: <b>{props.messageCount}</b>
        </p>
      </div>
    );
  };

  return (
    <Card title="Status & Connectivity" infoContent={infoContent()}>
      <div className="status">
        <img className="icon" src={statusCube} alt="cube status" />
        <div className="connection-wrapper">
          <img
            className="icon"
            src={props.isCubeConnected === undefined ? iconCheckmark : props.isCubeConnected ? iconCheckmarkConnected : iconCrossmark}
            alt={`cube ${props.isCubeConnected === undefined ? "connecting" : props.isCubeConnected ? "connected" : "disconnected"}`}
          />
          <div
            className={`connection-line ${props.isCubeConnected === true ? "connected" : props.isCubeConnected === false ? "disconnected" : ""}`}
          />
        </div>
        <img className="icon" src={statusCloud} alt="cloud status" />
        <div className="connection-wrapper">
          <img className="icon delay" src={iconCheckmarkConnected} alt="cloud connected" />
          <div className="connection-line-cloud" />
        </div>
        <img className="icon" src={statusDashboard} alt="dashboard status" />
      </div>
    </Card>
  );
}

export default StatusConnectivity;
