import { useEffect, useState } from "react";
import "./Dashboard.scss";
import logo from "../../assets/logo-white.svg";
import { useMsal } from "@azure/msal-react";
import { ICube } from "../../models/ICube";
import { BrokerService } from "../../services/BrokerService";
import { Quaternion } from "three";
import { CUBE_SCOPE } from "../Login/Login";
import { useNavigate } from "react-router-dom";
import { AuthenticationResult } from "@azure/msal-browser";
import TemperatureCard from "../../components/Card/Temperature/TemperatureCard";
import { Toaster } from "react-hot-toast";
import ThrowingCard, { ThrowStatus } from "../../components/Card/Throwing/ThrowingCard";
import CubeRotationCard from "../../components/Card/CubeRotation/CubeRotationCard";
import StatusConnectivity from "../../components/Card/StatusConnectivity/StatusConnectivity";
import IconButton from "../../components/IconButton/IconButton";
import Tooltip from "../../components/Tooltip/Tooltip";

function Dashboard() {
  const { instance, accounts } = useMsal();
  const [messageCount, setMessageCount] = useState(0);
  const [quaternion, setQuaternion] = useState<Quaternion | undefined>(undefined);
  const [temperature, setTemperature] = useState(0);
  const [throwHeight, setThrowHeight] = useState<number | undefined>(undefined);
  const [isCubeConnected, setIsCubeConnected] = useState<boolean | undefined>(undefined);
  const [cube, setCube] = useState<ICube | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    const connect = () => {
      if (sessionStorage.getItem("cube")) {
        const cube = JSON.parse(sessionStorage.getItem("cube")!) as ICube;
        setCube(cube);
        setTimeout(async () => {
          await instance
            .acquireTokenSilent({ account: accounts[0], scopes: CUBE_SCOPE })
            .then(async (result) => establishSocketConnection(result))
            .catch(() => navigate("/"));
        }, 1900);
      } else {
        navigate("/");
      }
    };
    connect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const establishSocketConnection = async (result: AuthenticationResult) => {
    const cube = JSON.parse(sessionStorage.getItem("cube")!) as ICube;
    const isCubeConnected = await BrokerService.isCubeConnected(result.accessToken, accounts[0].username);
    setIsCubeConnected(isCubeConnected);
    if (cube && isCubeConnected) {
      const ws = await BrokerService.connect(result.accessToken, cube.publisherId!);
      let messageCount = 0;
      ws.onmessage = (ev) => {
        setMessageCount(++messageCount);

        const d = JSON.parse(ev.data);

        const q = d.Message.quaternion;
        if (q) {
          setQuaternion(new Quaternion(q.x, q.y, q.z, q.w));
        }

        if (d.Message.temperature) {
          setTemperature(d.Message.temperature);
        }

        if (d.Message.throw_status === ThrowStatus.CAUGHT) {
          setThrowHeight(Number(d.Message.height));
        }

        if (d.Message.text && d.Message.text === "Publisher Disconnected") {
          setIsCubeConnected(false);
        } else if (d.Message.text && d.Message.text === "Publisher Connected") {
          setIsCubeConnected(true);
        }
      };
    }
  };

  const keyFacts = [
    {
      title: "Der inoCube",
      fact: <>
        Der inoCube verbindet einen Großteil der cross-funktionalen Expertise von inovex.
        <br />
        Durch spielerische Herausforderungen, wie z. B. Erkennung der Echtzeit Lage und Temperatur- sowie Wurferkennung, werden Themen aus dem Projektgeschäft aufgegriffen – darunter Echtzeit Datenauswertung, Datenanalyse (inkl. Machine Learning), Sensorik und viele weitere.
      </>,
    },
    {
      title: "Technische Umgebung",
      fact: <>
        Microsoft Azure, React, Golang, Zephyr, Nordic Thingy
      </>,
    },
    {
      title: "Das Vorgehen",
      fact: <>
        Ein cross-funktionales Expert:innen-Team der Bereiche Product Management, UX-Design, Embedded Systems, Cloud, Backend- und Frontend-Entwicklung sowie Data Analytics hat ein Vorgehen gewählt, das typisch für Kundenprojekte ist. Mit fester Budgetvorgabe, Stakeholdern und einer klaren Zielsetzung fanden verschiedene Workshops und Experimente statt, begleitet von einer iterativen, inkrementellen Implementierung.
      </>,
    },
    {
      title: "Ein zukunftsorientiertes Projekt",
      fact: <>
        Der inoCube ist als IoT-Showcase gestartet und wird konstant weiterentwickelt - auch in anderen technologischen Anwendungsbereichen. Für inovex ist darüber hinaus eine Plattform entstanden, die es ermöglicht, abseits von Kundenprojekten die cross-funktionale Zusammenarbeit zu fördern und die Begeisterung für innovative Technologielösungen mit anderen zu teilen.
      </>,
    },
  ];

  const renderedFacts = keyFacts.map((f, i) => <div key={i}>
    <p>
      <b style={{ marginRight: 20, fontSize: 13 }}>{i + 1}</b>
      {f.title}
    </p>
    <p style={{ fontSize: 12 }}>
      {f.fact}
    </p>
    <br />
  </div>
  );

  return (
    <div className="dashboard">
      <div className="bg"></div>
      <div className="header">
        <div className="logo-wrapper">
          <img className="logo" src={logo} alt="inocube logo" />
          <div className="title">inoCube Dashboard - {cube?.name}</div>
        </div>
        <IconButton iconName="LightBulb" iconSize={24} text="Key Facts inoCube" tooltipId="key-facts" />
        <Tooltip id="key-facts">
          <h4>Key Facts zum inoCube</h4>
          {renderedFacts}
        </Tooltip>
      </div>
      <div className="tiles">
        <div className="left">
          <StatusConnectivity isCubeConnected={isCubeConnected} messageCount={messageCount} />
          <div className="bottom">
            <CubeRotationCard isCubeConnected={isCubeConnected} quaternion={quaternion} />
            <div>
              <ThrowingCard throwHeight={throwHeight} />
            </div>
          </div>
        </div>
        <div className="right">
          <TemperatureCard temperature={temperature} />
        </div>
      </div>
      <Toaster position="top-right" />
    </div>
  );
}

export default Dashboard;
