import { IPublishersResult } from "../models/IBrokerResults"

class BrokerService {
    BrokerURL: string

    constructor() {
        var host = window.location.hostname
        if (host == "localhost") {
            // NOTE: use prod instance for development
            host = `dashboard.inocube.inovex.io`
        }

        this.BrokerURL = "https://cubebroker." + host
    }

    public getPublishers = async (accessToken: string) => {
        const response = await fetch(
            this.BrokerURL + "/publisher",
            { method: "GET", headers: { "Authorization": `Bearer ${accessToken}` } },
        )
        if (!response.ok) return []

        return (await response.json() as IPublishersResult).PublisherDeviceIds
    }

    public isCubeConnected = async (accessToken: string, userName: string) => {
        const response = await fetch(
            this.BrokerURL + "/publisher",
            { method: "GET", headers: { "Authorization": `Bearer ${accessToken}` } },
        )
        if (!response.ok) return false

        const publishers = (await response.json() as IPublishersResult).PublisherDeviceIds
        const publisherId = publishers.find((p) => p.indexOf(userName) > -1);
        return publisherId ? true : false
    }

    public connect = async (accessToken: string, publisherId: string) => {
        return new WebSocket(
            `${this.BrokerURL.replace("https://", "wss://")}/ws?device_id=dashboard&subscribe_to=${publisherId}`,
            ["access_token", accessToken],
        )
    }
}

const service = new BrokerService()
export {
    service as BrokerService
}
