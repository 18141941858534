import React from "react";
import IconButton from "../IconButton/IconButton";
import Tooltip from "../Tooltip/Tooltip";
import "./Card.scss";

export interface CardProps {
  title: string;
  infoContent: JSX.Element;
  styles?: {
    cardStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
  };
}

function Card(props: React.PropsWithChildren<CardProps>) {
  return (
    <div className="card" style={props.styles?.cardStyle}>
      <div className="title" style={props.styles?.titleStyle}>
        {props.title} <IconButton tooltipId={props.title} iconName="LightBulb" iconSize={24} />
      </div>
      <div className="content">{props.children}</div>
      <Tooltip id={props.title}>{props.infoContent}</Tooltip>
    </div>
  );
}

export default Card;
