import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import Card from "../Card";
import toast from "react-hot-toast";
import "./ThrowingCard.scss";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  BubbleDataPoint,
  ChartData,
  ChartOptions,
  ScatterDataPoint,
  registerables as registerablesJS,
} from "chart.js";
import party from "party-js";

ChartJS.register(...registerablesJS);
ChartJS.register(Filler, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export enum ThrowStatus {
  CAUGHT = "caught",
}

export interface ThrowingCardProps {
  throwHeight?: number;
}

const chartOptions: ChartOptions = {
  scales: { yAxes: { min: 0, max: 3, title: { display: true, text: "Meter" } } },
  plugins: {
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
};

function ThrowingCard(props: ThrowingCardProps) {
  const { throwHeight } = props;
  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<number[]>([]);
  const [challengeWon, setChallengeWon] = useState(false);
  const [throwCount, setThrowCount] = useState(0);

  useEffect(() => {
    if (chartData.length > 5) {
      chartData.pop();
    }
    if (throwHeight && throwHeight > 1 && !challengeWon) {
      setChallengeWon(true);
      toast.success("Challenge gewonnen!", { duration: 5000 });
    }
    if (throwHeight) {
      chartData.unshift(Number(throwHeight.toFixed(2)));
      setThrowCount(throwCount + 1);
      console.log(chartData);
    }
    setChartData(chartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throwHeight]);

  useEffect(() => {
    if (challengeWon) {
      const element = document.getElementById("throwChallengeText");
      party.confetti(element as any);
    }
  }, [challengeWon]);

  const createGradient = () => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.chartArea.height * 1.5);

    gradient.addColorStop(0, "rgb(0, 87, 255)");
    gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

    return gradient;
  };

  const chartLabels = chartData.length === 0 ? ["Kein Wurf"] : chartData.slice(0, 5).map((_, i) => `${throwCount - i}. Wurf`);

  const chartDatasets: ChartData<"line", (number | ScatterDataPoint | BubbleDataPoint | null)[], unknown> = {
    labels: chartLabels,
    datasets: [{ fill: true, backgroundColor: createGradient, data: chartData }],
  };

  const infoContent = () => {
    return (
      <div>
        <h4>Wurferkennung</h4>
        <p style={{ fontSize: 11 }}>
          Die Wurferkennung läuft als containerisierte Anwendung in der Cloud. 
          <br />
          Die Lage- und Beschleunigungsdaten des inoCubes werden dort nahezu in Echtzeit ausgewertet und die Ergebnisse an das Dashboard weitergereicht. 
          <br />
          Um einen Wurf von einer schnellen Bewegung zu unterscheiden, werden die Beschleunigungswerte des Würfels ausgewertet und mit einem hinterlegten Muster verglichen.
        </p>
        <p style={{ fontWeight: "600", fontSize: 12 }}>Anzahl der Würfe: {throwCount}</p>
      </div>
    );
  };

  return (
    <Card title="Wurfchallenge" styles={{ cardStyle: { width: 500 } }} infoContent={infoContent()}>
      <div className="status-container">
        <div className="status-text">Anzahl der Würfe: {throwCount}</div>
      </div>
      <Chart ref={chartRef} type="bar" data={chartDatasets} options={chartOptions} />
      <div id="throwChallengeText" className="challenge-container">
        Wirf den inoCube mindestens einen Meter in die Luft, um die Challenge zu gewinnen!
        <br />
        {challengeWon && <span>Du hast die Challenge gewonnen! 🥳</span>}
      </div>
    </Card>
  );
}

export default ThrowingCard;
